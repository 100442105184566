:root {
  --candidate-width: 30vw;
  --candidate-item-color: #27272A;
}

#candidate-list {
  position: relative;
  width: var(--candidate-width);
}

#candidate {
  background-color: var(--candidate-item-color);
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--tini-brand-text-color);
  margin-bottom: 10px;
  padding: 8px;
  text-align: center;
  user-select: none;
  width: var(--candidate-width);
}

#candidate-list.locked > #candidate {
  opacity: .6;
}

#candidate:not(:active) {
  transition-duration: 200ms;
  transition-property: left, top;
  transition-timing-function: ease-out;
}
