.button-icon {
  fill: var(--tini-brand-text-color);
  margin-inline-end: 8px;
}

#button-element {
  align-items: center;
  background-color: var(--tini-brand-color);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: var(--tini-border-radius-rounded);
  box-sizing: border-box;
  color: var(--tini-brand-text-color);
  cursor: pointer;
  display: flex;
  font-size: var(--tini-font-size-medium);
  height: fit-content;
  justify-content: center;
  padding: 8px 12px;
  user-select: none;
  width: var(--button-width);
}

#button-element:hover {
  opacity: .8;
}

#button-element:active {
  opacity: .6;
}

#button-element.pill {
  border-radius: var(--tini-border-radius-pill);
}

#button-element.reverse {
    background-color: var(--tini-brand-text-color);
    color: var(--tini-brand-color);
}

#button-element.reverse > .button-icon {
    fill: var(--tini-brand-color);
}

#button-element.bordered {
  border: 1px solid var(--tini-brand-text-color);
}

#button-element.reverse.bordered {
  border: 1px solid var(--tini-brand-color);
}

#button-element.trailing-icon {
  flex-direction: row-reverse;
}

#button-element.trailing-icon > .button-icon {
  flex-direction: row-reverse;
  margin-inline-end: unset;
  margin-inline-start: 8px;
}

#button-element.disabled {
  opacity: .6;
  pointer-events: none;
}
