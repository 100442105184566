#main {
  margin-top: 50px;
}

#voting-name-container {
  text-align: center;
}

#voting-name {
  color: var(--tini-brand-color);
}

#table {
  border-collapse: collapse;
}

.row + .row {
  border-top: 1px solid var(--tini-divider-color);
}

.row > * {
  font-size: var(--tini-font-size-large);
  padding: 8px 0;
  text-align: start;
}

.index {
  width: 200px;
}

.name {
  font-weight: var(--tini-font-weight-bold);
}
