:root {
  /* font weights */
  --tini-font-weight-bold: 700;
  --tini-font-weight-medium: 500;
  --tini-font-weight-regular: 400;

  /* border radii */
  --tini-border-radius-pill: 20px;
  --tini-border-radius-rounded: 4px;
  --tini-border-radius-rounded-2: 8px;

  /* sizes */
  --tini-font-size-base: 14px;
  --tini-font-size-large: 18px;
  --tini-font-size-medium: 16px;
  --tini-font-size-small: 12px;

  /* colors */
  --tini-border-default-color: #DDDDE3;
  --tini-brand-color: #0A68FF;
  --tini-brand-text-color: #FFFFFF;
  --tini-divider-color: #EBEBF0;
  --tini-error-color: #FF424F;
  --tini-green-60: #00AB56;
  --tini-icon-default-color: #515158;
  --tini-main-background-color: #FFFFFF;
  --tini-main-text-color: #27272A;
  --tini-placeholder-text-color: #808089;
  --tini-orange-60: #FC820A;
  --tini-overlay-background-color: rgba(0, 0, 0, .4);
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
}

:root * {
  color: var(--tini-main-text-color);
  font-family: 'Inter';
}

h1 {
  font-size: var(--tini-font-size-large);
  font-weight: var(--tini-font-weight-bold);
}

.margin-centralize {
  margin: auto;
  width: fit-content;
}
