
#voting-name {
  text-align: center;
}

#candidate-list-container {
  display: flex;
  position: relative;
  margin-top: 50px;
}

#side-panel-container {
  --floating-panel-width: 30vw;
  top: -50px;
  position: absolute;
  pointer-events: none;
  right: 8px;
}

#done-button-container {
  left: 8px;
  position: absolute;
  top: -42px;
}

@media (max-aspect-ratio: 5/6) {
  #side-panel-container {
    /* In mobile devices, the floating panel should behave as a dialog */
    --floating-panel-width: 90vw;
    right: calc((100vw - var(--floating-panel-width)) / 2);
  }
}
