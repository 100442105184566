#nav-bar {
  align-items: center;
  background-color: var(--tini-brand-color);
  display: flex;
  padding: 12px;
}

#nav-links {
  align-items: center;
  display: flex;
}

#home-link{
  color: var(--tini-brand-text-color);
}

#nav-bar-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
}

@media (max-aspect-ratio: 1) {
  #nav-bar {
    align-items: start;
    flex-direction: column;
  }

  #nav-bar-container {
    justify-content: start;
  }
}

.nav-bar-button {
  align-items: center;
  display: flex;
  font-weight: var(--tini-font-weight-medium);
  margin-inline-end: 32px;
  text-decoration: none;
  user-select: none;
}

.nav-bar-button-icon {
  fill: var(--tini-brand-text-color);
  margin-inline-end: 8px;
}
